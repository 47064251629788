import { useTranslation } from 'next-i18next'
import { Collapse } from '@lockerpm/design'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { Link } from '@/components/common/CustomLink'

export default function Questions() {
  const { t } = useTranslation('home')

  return (
    <>
      <section>
        <hr className="mx-12" />

        <div className="section-container py-20 flex flex-wrap">
          <div className="lg:w-5/12 w-full">
            <h2 className="lg:text-display-xl sm:text-display-lg text-display-md text-black font-semibold mb-8 sticky top-24">
              {t('questions.title')}
            </h2>
          </div>

          <div className="w-1/12"></div>

          <div className="lg:w-6/12 w-full">
            <Collapse
              ghost
              accordion
              expandIcon={(p) =>
                p.isActive ? (
                  <MinusOutlined
                    style={{ fontSize: '1.5rem', lineHeight: '2rem', marginTop: 5 }}
                    className="text-primary"
                  />
                ) : (
                  <PlusOutlined
                    className="text-primary"
                    style={{ fontSize: '1.5rem', lineHeight: '2rem', marginTop: 5 }}
                  />
                )
              }
            >
              {t('questions.items', { returnObjects: true }).map((item, index) => (
                <Collapse.Panel
                  key={index}
                  header={
                    <span className="text-black sm:text-display-xs text-xl font-semibold">
                      {item.title}
                    </span>
                  }
                  className="mb-4"
                >
                  <p className="text-lg">
                    {item.desc.map((desc, dIndex) =>
                      typeof desc === 'string' ? (
                        <span
                          key={`desc${dIndex}`}
                          dangerouslySetInnerHTML={{ __html: desc }}
                        ></span>
                      ) : (
                        <Link
                          className="text-primary font-medium"
                          key={`desc${dIndex}`}
                          href={desc.url}
                          target="_blank"
                        >
                          {desc.content}
                        </Link>
                      )
                    )}
                  </p>
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        </div>

        <hr className="mx-12" />
      </section>
    </>
  )
}
