import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import LazyLoad from 'react-lazyload'

export default function Perspective() {
  const { t } = useTranslation('home')
  return (
    <>
      <section className="section-container py-20">
        <h2 className="lg:text-display-xl text-display-md text-black font-semibold mb-16">
          {t('perspective.title')}
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {t('perspective.items', { returnObjects: true }).map((item, index) => (
            <Link target="_blank" href={item.url} key={index}>
              <div className="bg-block rounded-xl overflow-hidden h-full">
                <LazyLoad height={200}>
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    preload="none"
                    style={{
                      width: '100%',
                    }}
                  >
                    <source
                      src={`/images/home/perspective/item-${index + 1}.mp4`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </LazyLoad>
                <div className="p-4">
                  <p className="text-lg font-semibold text-primary mb-1">{item.title}</p>
                  <p className="text-md mb-0">{item.desc}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </>
  )
}
