import React, { useRef, useEffect } from 'react'
import styles from './index.module.scss'

type Props = {
  vertical?: boolean
  inverted?: boolean
  children: React.ReactNode
  duration?: any
  nowrap?: boolean
  pauseOnHover?: boolean
}

export default function RunningText(props: Props) {
  const { vertical, inverted, children, duration, nowrap, pauseOnHover } = props

  const ref = useRef(null)

  const animationClass =
    styles[`running-${vertical ? 'vertical' : 'horizontal'}${inverted ? '-inverted' : ''}`]

  const play = () => {
    if (pauseOnHover) {
      ref.current.style.animationPlayState = 'running'
    }
  }

  const pause = () => {
    if (pauseOnHover) {
      ref.current.style.animationPlayState = 'paused'
    }
  }

  useEffect(() => {
    ref.current?.addEventListener('mouseover', pause)
    ref.current?.addEventListener('mouseout', play)
    return () => {
      ref.current?.removeEventListener('mouseover', pause)
      ref.current?.removeEventListener('mouseout', play)
    }
  }, [])

  return (
    <div
      ref={ref}
      className={animationClass}
      style={{
        animationDuration: duration,
      }}
    >
      <div className={`${styles.content} ${nowrap ? '' : 'whitespace-normal'}`}>{children}</div>
      <div className={`${styles.content} ${nowrap ? '' : 'whitespace-normal'}`}>{children}</div>
    </div>
  )
}
