import { useTranslation } from 'next-i18next'
import { RightOutlined } from '@ant-design/icons'
import LazyLoad from 'react-lazyload'

export default function SecurityAndTransparency() {
  const { t } = useTranslation('secrets')

  return (
    <>
      <section className="section-container sm:py-20 py-10">
        <h2
          className="lg:text-display-xl sm:text-display-lg text-display-md text-black font-semibold sm:mb-16 mb-8"
          style={{ maxWidth: 666 }}
        >
          {t('security.title')}
        </h2>

        {t('security.items', { returnObjects: true }).map((item, index) => (
          <div className="flex flex-wrap lg:mb-6 mb-10" key={index}>
            <div
              className={`lg:w-7/12 w-full items-center justify-center flex mb-8 ${index % 2 ? 'lg:order-1 lg:pl-36' : 'lg:pr-36'}`}
            >
              <div>
                <h3 className="text-display-xs font-medium mb-2">{item.title}</h3>
                <p className="text-lg mb-6 text-secondary-65">{item.desc}</p>
                <a
                  href={item.url}
                  target="_blank"
                  className="lg:text-xl text-lg font-semibold text-primary"
                >
                  {item.link} <RightOutlined />
                </a>
              </div>
            </div>

            <div className="lg:w-5/12 w-full rounded-3xl overflow-hidden">
              <LazyLoad height={200}>
                <video
                  preload="none"
                  autoPlay
                  playsInline
                  muted
                  loop
                  className="scale-[1.02]"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <source
                    src={`/images/secrets/security/security2x-${index + 1}.mp4`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </LazyLoad>
            </div>
          </div>
        ))}
      </section>
    </>
  )
}
