import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'

import * as Sentry from '@sentry/nextjs'

import DefaultLayout from '@/layouts/default'
import Header from '@/components/pages/home/Header'
import Products from '@/components/pages/home/Products'
import Why from '@/components/pages/home/Why'
import Security from '@/components/pages/home/Security'
import AccessData from '@/components/pages/home/AccessData'
import Testimonials from '@/components/pages/home/Testimonials.tsx'
import Platforms from '@/components/pages/home/Platforms'
import Perspective from '@/components/pages/home/Perspective'
import Questions from '@/components/pages/home/Questions'
import CTA from '@/components/pages/home/CTA'

export async function getServerSideProps({ locale }) {
  try {
    return {
      props: {
        data: {},
        locale,
        ...(await serverSideTranslations(locale)),
      },
    }
  } catch (error) {
    Sentry.captureMessage(error)
    return {
      props: {
        data: {},
        locale,
        ...(await serverSideTranslations(locale)),
      },
    }
  }
}

export default function Home() {
  const { t } = useTranslation('home')

  return (
    <>
      <Head>
        {/* Schema */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@context":"https://schema.org",
              "@type":"FAQPage",
              "mainEntity":[
                  {
                    "@type":"Question",
                    "name":"What is Locker?",
                    "acceptedAnswer":{
                      "@type":"Answer",
                      "text":"Locker is a two-in-one product that includes comprehensive services to secure and simplify your digital life: password manager and secrets manager. With Locker, your passwords and confidential data, your online identity, and secrets from your software project are kept completely safe. Locker also comes with a variety of additional features that help simplify and streamline your password and secret management and improve your overall productivity."
                    }
                  },
                  {
                    "@type":"Question",
                    "name":"Is Locker safe?",
                    "acceptedAnswer":{
                      "@type":"Answer",
                      "text":"Definitely YES. We use End-to-End Encryption, Zero-knowledge Protocol, and State-of-the-art cybersecurity technologies to ensure that your data is accessible and readable to you ONLY, not anyone else - even the Locker team. As long as you keep your Master Password to yourself, Locker is a completely safe place to keep sensitive information."
                    }
                  },
                  {
                    "@type":"Question",
                    "name":"Can Locker be trusted?",
                    "acceptedAnswer":{
                      "@type":"Answer",
                      "text":"Totally. Locker takes advantage of reliable encryption solutions, leverages AWS services, and is audited on a regular basis by the third-party WhiteHub Bug Bounty program. Locker is also open-source software as a commitment to continuously build a trusted product."
                    }
                  },
                  {
                    "@type":"Question",
                    "name":"Does Locker store data in the cloud?",
                    "acceptedAnswer":{
                      "@type":"Answer",
                      "text":"Yes, Locker data is stored in the cloud and secured with end-to-end encryption and zero-knowledge protocol to ensure that it is not violated by any unauthorized access. Even in the event of a data breach, the data is useless as the attackers can not decrypt your data without your Master Password."
                    }
                  }
              ]
            }
            `,
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@context":"https://schema.org",
              "@type":"SoftwareApplication",
              "name":"Locker Password Manager",
              "operatingSystem":"ANDROID, iOS, macOS, Windows, Linux, Web Browser",
              "applicationCategory":"SecurityApplication",
              "aggregateRating":{
                "@type":"AggregateRating",
                "ratingValue":"5.0",
                "ratingCount":"4236"
              },
              "offers":{
                "@type":"Offer",
                "price":"5.99",
                "priceCurrency":"USD"
              }
            }
            `,
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
           {
              "@context":"https://schema.org",
              "@type":"SoftwareApplication",
              "name":"Locker Secrets Manager",
              "operatingSystem":"ANDROID, iOS, macOS, Windows, Linux, Web Browser",
              "applicationCategory":"SecurityApplication",
              "aggregateRating":{
                "@type":"AggregateRating",
                "ratingValue":"5.0",
                "ratingCount":"4122"
              },
              "offers":{
                "@type":"Offer",
                "price":"0",
                "priceCurrency":"USD"
              }
            }
            `,
          }}
        />
        {/* Schema end */}
      </Head>

      <DefaultLayout title={t('seo.title')} desc={t('seo.desc')}>
        <Header />
        <Products />
        <Why />
        <Security />
        <AccessData />
        <Testimonials />
        <Platforms />
        <Perspective />
        <Questions />
        <CTA />
      </DefaultLayout>
    </>
  )
}
