import { useRouter } from 'next/router'
import NextLink from 'next/link'

export const postUrlToParent = (url: string) => {
  const DOMAINS = ['https://locker.io', 'https://staging.locker.io', 'https://demo.locker.io:3011']
  DOMAINS.forEach((domain) => {
    window.parent.postMessage(
      {
        event: 'url',
        value: url,
      },
      domain
    )
  })
}

export const Link = (props: any) => {
  const { children, href, ...otherProps } = props
  const router = useRouter()
  const isIframe = router.query.mode === 'iframe'

  const iframeOnClick = (e: Event) => {
    e.preventDefault()
    postUrlToParent(href)
  }

  if (isIframe) {
    return (
      <NextLink href={href} {...otherProps} onClick={iframeOnClick}>
        {children}
      </NextLink>
    )
  }

  return (
    <NextLink href={href} {...otherProps}>
      {children}
    </NextLink>
  )
}
