import { useTranslation } from 'next-i18next'
import { RightOutlined } from '@ant-design/icons'
import { Link } from '@/components/common/CustomLink'
import Image from 'next/image'
import LazyLoad from 'react-lazyload'

export default function Products() {
  const { t } = useTranslation('home')
  return (
    <>
      <section id="products" className="section-container py-20">
        <h2 className="lg:text-display-xl sm:text-display-lg text-display-md text-black font-semibold mb-16">
          {t('products.title')}
        </h2>

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
          {/* PM */}
          <div>
            <div className="w-full rounded-xl overflow-hidden">
              <LazyLoad height={200}>
                <video
                  preload="none"
                  autoPlay
                  playsInline
                  muted
                  loop
                  style={{
                    width: '100%',
                  }}
                >
                  <source src={`/images/home/products/pm.mp4`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </LazyLoad>
            </div>

            <div className="mt-4 mb-1 flex">
              <Image
                className="h-14 w-14 mr-3"
                src={`/images/home/products/pm.svg`}
                alt="Password Manager"
                height={56}
                width={56}
              />
              <h3
                className="text-display-xs font-medium mt-2.5"
                dangerouslySetInnerHTML={{ __html: t('products.pm_title') }}
              ></h3>
            </div>
            <p className="text-lg mb-4">{t('products.pm_desc')}</p>
            <Link target="_blank" href="/passwords">
              <div className="primary-btn-outline btn-small rounded-full sm:w-auto w-full">
                {t('products.learn_more')} <RightOutlined />
              </div>
            </Link>
          </div>
          {/* PM end */}

          {/* SM */}
          <div>
            <div className="w-full rounded-xl overflow-hidden">
              <LazyLoad height={200}>
                <video
                  preload="none"
                  autoPlay
                  playsInline
                  muted
                  loop
                  style={{
                    width: '100%',
                  }}
                >
                  <source src={`/images/home/products/sm.mp4`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </LazyLoad>
            </div>

            <div className="mt-4 mb-1 flex">
              <Image
                className="h-14 w-14 mr-3"
                src={`/images/home/products/sm.svg`}
                alt="Secrets Manager"
                height={56}
                width={56}
              />
              <h3
                className="text-display-xs font-medium mt-2.5"
                dangerouslySetInnerHTML={{ __html: t('products.sm_title') }}
              ></h3>
            </div>
            <p className="text-lg mb-4">{t('products.sm_desc')}</p>
            <Link target="_blank" href="/secrets">
              <div className="primary-btn-outline rounded-full btn-small sm:w-auto w-full">
                {t('products.learn_more')} <RightOutlined />
              </div>
            </Link>
          </div>
          {/* SM end */}
        </div>
      </section>
    </>
  )
}
