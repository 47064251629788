import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import Image from 'next/image'
import warningImg from '@/public/images/home/access-data/warning.png'

export default function AccessData() {
  const { t, i18n } = useTranslation('home')

  const url =
    i18n.language === 'vi'
      ? '/blog/locker-co-the-xem-duoc-du-lieu-cua-ban-khong'
      : '/blog/can-locker-access-and-read-your-data'

  return (
    <>
      <section
        className="py-[72px] relative overflow-hidden"
        style={{
          backgroundColor: '#FFF7E6',
        }}
      >
        <Image
          className="hidden lg:block absolute z-0"
          alt="warning"
          src={warningImg}
          style={{
            right: '-15%',
            top: '55%',
            transform: 'translateY(-50%)',
            width: '50%',
            height: 'auto',
          }}
        />
        <div className="section-container relative z-10">
          <div className="lg:w-8/12 w-full">
            <h2 className="text-display-md text-black font-semibold mb-6">
              {t('access_data.title')}
            </h2>
            <p className="text-display-xs font-semibold text-black mb-6">
              {t('access_data.not_1')}
              <span className="text-danger font-medium">{t('access_data.not_2')}</span>!
            </p>
            <p className="text-lg mb-6">
              {t('access_data.desc_1')} {t('access_data.desc_2')}
            </p>
            <p className="text-lg">
              {t('access_data.read_more')}
              <Link className="text-info" target="_blank" href={url}>
                {url}
              </Link>
            </p>
          </div>
        </div>
      </section>
    </>
  )
}
