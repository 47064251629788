import { useTranslation } from 'next-i18next'
import Image from 'next/image'

export default function Why() {
  const { t } = useTranslation('home')
  return (
    <>
      <section className="bg-block py-20">
        <div className="section-container">
          <h2 className="lg:text-display-xl text-display-md text-black font-semibold mb-16">
            {t('why.title')}
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
            {t('why.items', { returnObjects: true }).map((item, index) => (
              <div key={index}>
                <Image
                  className="h-16 w-16 mb-4"
                  src={`/images/home/why/item-${index + 1}.svg`}
                  alt={item.title}
                  height={64}
                  width={64}
                />
                <h3 className="text-xl font-semibold text-primary mb-1">{item.title}</h3>
                <p className="text-lg mb-0">{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
