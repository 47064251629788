import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import LatestNews from '@/components/common/LatestNews'
import { RightOutlined } from '@ant-design/icons'
import Businesses from '@/components/pages/secrets/Businesses'
import Slider from 'react-slick'
import Link from 'next/link'
import ENDPOINT from '@/config/endpoint'

export default function Header() {
  const { t, i18n } = useTranslation('home')

  const [tabIndex, setTabIndex] = useState(0)

  let textInterval = null
  const title1Ref = useRef(null)
  const title3Ref = useRef(null)

  const [title1, title2, title3] = t('header.title', { returnObjects: true })

  const onVideoEnd = (index) => {
    if (index === tabIndex) {
      setTabIndex((tabIndex + 1) % 3)
    }
  }

  useEffect(() => {
    textInterval = setInterval(() => {
      title1Ref.current.slickNext()
      title3Ref.current.slickNext()
    }, 4000)
    return () => {
      clearInterval(textInterval)
    }
  }, [])

  return (
    <>
      <section className="section-container flex flex-wrap items-end relative pb-20 overflow-hidden">
        <div className="lg:w-5/12 w-full mt-8 relative z-10">
          <LatestNews tags={['Home']} />

          {/* Hidden h1 for page indexing */}
          <h1 className="hidden">
            {title1[0]}
            {title2}
            {title3[0]}
          </h1>

          <div className="my-6 relative">
            {/* SEO Placeholder */}
            <div className="absolute left-0 top-0 w-full">
              <p className="md:text-display-2xl sm:text-display-xl text-display-lg text-black font-semibold lg:text-left text-center mb-0">
                {title1[0]}
              </p>
            </div>

            <div className="absolute left-0 bottom-0 w-full">
              <p className="md:text-display-2xl sm:text-display-xl text-display-lg text-black font-semibold lg:text-left text-center mb-0">
                {title3[0]}
              </p>
            </div>
            {/* SEO Placeholder end */}

            <Slider
              ref={title1Ref}
              dots={false}
              arrows={false}
              pauseOnHover={false}
              infinite
              fade
              slidesToShow={1}
              slidesToScroll={1}
            >
              {title1.map((item, index) => (
                <p
                  key={index}
                  className="md:text-display-2xl sm:text-display-xl text-display-lg text-black font-semibold lg:text-left text-center mb-0 bg-white"
                >
                  {item}
                </p>
              ))}
            </Slider>

            <p className="text-primary md:text-display-2xl sm:text-display-xl text-display-lg font-semibold lg:text-left text-center mb-0">
              {title2}
            </p>

            <Slider
              ref={title3Ref}
              dots={false}
              arrows={false}
              infinite
              fade
              slidesToShow={1}
              slidesToScroll={1}
              pauseOnHover={false}
            >
              {title3.map((item, index) => (
                <p
                  key={index}
                  className="md:text-display-2xl sm:text-display-xl text-display-lg text-black font-semibold lg:text-left text-center mb-0 bg-white"
                >
                  {item}
                </p>
              ))}
            </Slider>
          </div>

          <div className="flex flex-wrap items-center lg:justify-start justify-center">
            <Link target="_blank" href={`${ENDPOINT.ID_GENERAL_REGISTER}`} className="mr-2 mb-3">
              <div className={`primary-btn ${i18n.language === 'vi' ? 'px-6' : ''}`}>
                {t('header.btn_1')}
              </div>
            </Link>

            <a
              href="#products"
              className="text-primary sm:text-xl text-lg font-semibold py-3 px-9 mb-3"
            >
              {t('header.btn_2')} <RightOutlined />
            </a>
          </div>
        </div>

        <div className="lg:w-7/12 w-full mt-8 relative">
          {/* Placeholder */}
          <div
            className="relative opacity-0 z-0 w-full"
            style={{
              maxWidth: 719,
            }}
          >
            <div
              className="relative w-full"
              style={{
                paddingTop: '70.4%',
              }}
            ></div>
          </div>
          {/* Placeholder end */}

          {title1.map((_, index) => (
            <div key={index}>
              {index === tabIndex && (
                <div className="absolute z-20 left-0 top-0">
                  <video
                    preload="none"
                    autoPlay
                    playsInline
                    muted
                    onEnded={() => onVideoEnd(index)}
                    style={{
                      width: '100%',
                    }}
                  >
                    <source src={`/images/home/header/0${index + 1}.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>

      <Businesses />
    </>
  )
}
